@import "~jsoneditor/dist/jsoneditor.min.css";
@import "~ng-pick-datetime/assets/style/picker.min.css";
@import '~@angular/cdk/overlay-prebuilt.css';

@import url("../node_modules/@fortawesome/fontawesome-free/css/all.css");
@import url("../node_modules/primeng/resources/themes/nova-light/theme.css");
@import url("../node_modules/primeng/resources/primeng.min.css");
@import url("../node_modules/@angular/material/prebuilt-themes/deeppurple-amber.css");
@import '~primeicons/primeicons.css';

/* You can add global styles to this file, and also import other style files */


  body {
     overflow-x: hidden;
  }
  .btn-primary, .btn-primary:focus {
    color: #fff;  
    background-color: #0daaba;  
    border-color: #0daaba;  
  } 

  .btn-primary:hover, .btn-primary:active, .btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle, .btn-primary:disabled{
    color: #fff;  
    background-color: #097682;  
    border-color: #097682;  
  }
  .bg-dark {
    background-color:#000000!important;
}

  .leftText {
      text-align: left;
  }

  .rightText {
    text-align: right;
}

.centerText {
  text-align: center;
}

.center {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
}
.nopadding {
    padding: 0;
}
.padding-left-5 {
  padding-left: 5px!important;
 }
 .padding-left-6 {
  padding-left: 6px!important;
 }
 .padding-left-10 {
  padding-left: 10px;
 }
 .padding-left-12 {
  padding-left: 12px!important;
 }
.padding-left-15 {
 padding-left: 15px;
}

.padding-left-30 {
  padding-left: 30px;
 }
 .padding-right-5 {
  padding-right: 5px!important;
 }
 .padding-right-10 {
  padding-right: 10px;
 }
.padding-right-15 {
  padding-right: 15px;
 }
 .padding-right-30 {
  padding-right: 30px;
 }
.padding-right-0 {
  padding-right: 0px!important;
}

.padding-left-0 {
  padding-left: 0px!important;
}

.padding-top-18 {
  padding-top: 18px!important;
}

.closeButton {
  cursor: pointer;
  z-index: 1000;
}


.toolTip, .toolTip2 {
  position: absolute;
  display: none;
  min-width: 80px;
  height: auto;
 /* background: none repeat scroll 0 0 #ffffff; 
  border: 1px solid #6F257F;
  padding: 14px; */
  text-align: center;  
  line-height: 1;
  font: 14px sans-serif;
  padding: 12px;
 /* background: rgba(0, 0, 0, 0.8);
  color: rgb(185, 185, 185); */
  border-radius: 2px;
  z-index: 20000;
}

.toolTip th {
    color: white;
    background-color:grey;
    padding-left: 3px;
    padding-right: 3px;
    padding-top: 2px;
}

.toolTip td {
    color: grey;
    background-color:white;
    height:22px;
   
}

.td1 {
  
    border-top: 1px dashed grey;
    border-bottom: 1px dashed grey;
     border-left: 1px dashed grey;
    padding-top: 1px;
    padding-left:1px;
}

.td2 {
 
    border-top: 1px dashed grey;
    border-bottom: 1px dashed grey;
    padding-left: 3px;
    padding-right: 3px;
}
.tdPoint {
  color: white!important;
  background-color:blue!important;
  height:22px;
}
.td3 {
     border: 1px dashed grey;
      padding-left: 3px;
    padding-right: 3px;
}

/* fallback */
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialicons/v43/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2) format('woff2');
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  font-feature-settings: 'liga';
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}
/* confirm dialog */
.ui-dialog .ui-dialog-footer {
  height: 50px;
}
/* confirm button*/
.ui-dialog-footer .ui-button {
  float:right;
}

/*cancel button*/
.ui-dialog-footer .ui-button + .ui-button {
  background: lightgrey;
  float:left;
  color:#333333;
  border-color: lightgrey;
}
.ui-confirmdialog.ui-dialog .ui-dialog-content {
  height: 70px;
}
/*
.cancel{
  background: lightgrey;
  float:left;
  color:#333333;
  border-color: lightgrey;
}
*/
.text-align-center{
  text-align: center;
}
.text-align-right{
  text-align: right;
}
.text-align-left{
  text-align: left;
}
.centerDiv {
  margin: auto;
}
.margin-top-5{
  margin-top: 5px;
}
.margin-top-10 {
  margin-top: 10px;
}
.margin-bottom-0{
  margin-bottom: 0px;
}
.margin-bottom-10 {
  margin-bottom: 10px;
}
.margin-top-20{
  margin-top: 20px;
}
.margin-top-30{
  margin-top: 30px;
}
.margin-top-40{
  margin-top: 40px;
}
.margin-top-50{
  margin-top: 50px;
}
.margin-top-60{
  margin-top: 60px;
}
.font-12{
  font-size: 12px;
}
.margin-left-2{
  margin-left: 2px;
}
.margin-right-0{
  margin-right: 0px;
}


.margin-right-2{
  margin-right: 2px;
}

.margin-left-5{
  margin-left: 5px;
}
.margin-left-10{
  margin-left: 10px;
} 
.margin-right-5{
  margin-right: 5px;
}
.margin-right-15{
  margin-right: 15px;
}
.margin-left-20{
  margin-left: 20px;
} 
.margin-right-20{
  margin-right: 20px;
}

.margin-left-40{
  margin-left: 40px;
} 
.margin-right-40{
  margin-right: 40px;
}

/*
.ui-tooltip {
  overflow: visible!important;
  background-color: yellow;
  width:500px!important;
}
*/
.ui-tooltip {
  width: 500px!important;
  height: auto;
  display: inline-block;
}

.ui-tooltip .ui-tooltip-text {
  white-space: pre-wrap;
  overflow-wrap: break-word;
}

.marginLeft5 {
  margin-left: 5px;
}

.marginRight5 {
  margin-right: 5px;
}

.marginRight10 {
  margin-right: 10px;
}
.textAlignRight {
  text-align: right
}
.margin-bottom-20 {
  margin-bottom: 20px;
}

.ui-picklist .ui-picklist-droppoint {
  height: 0px;
  list-style-type: none;
}

.ui-picklist .ui-picklist-list li {
  margin: 0px!important;
/* padding: .125em;*/
  padding: 0!important;
}

.applyButton {
  position: absolute;
  right: 160px;
  border-radius: 3.2px;
}

.applyButtonChain {
  margin-left: 2px;
  padding-left: 5x;
  padding-right: 5px;
  position: absolute;
  right: 80px;
  border-radius: 3.2px;
}

app-charts{
  width: 100%;
}



.textAlignCenter {
  text-align: center;
}
.ui-picklist-source-controls {
  display: none!important;
}
.ui-picklist-target-controls {
  display: none!important;
}


.piHelp {
  float: right;
  font-size:2em!important;
  cursor: pointer;
}

.sectionTitle {
  font-weight: normal;
  text-align: center;
}



.dynamic{
  font-size:13px;
  font-style: italic;
}

/* disable hover toolbar for plotly */
.modebar {
  display: none;
}

.margin-bottom-3 {
  margin-bottom: 3px;
}

.label-vertical{
  writing-mode: vertical-rl;
  text-orientation: upright;
}

#workflowDropdown, #dashboardSourceDropdown {
  width: 100%;
}



.inline-flex {
  display: inline-flex;
}
.applyButtonGroup {
  float: right;
}
.applyButtonGroup button {
  margin-right: 5px!important;
  margin-left: 5px!important;
  border-radius: 0.3rem!important;
}

.input-group-form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + .75rem + 2px);
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-top-right-radius: .25rem;
  border-bottom-right-radius: .25rem;
  -webkit-transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

cdk-virtual-scroll-viewport {
  min-height: 100%;
}

.dropdownBtnGroup {
  border: 0;
  border-radius: 0;
}


/* make sure all dropdowns are at the front */
.cdk-overlay-pane, .cdk-overlay-container {
  z-index: 20000!important;
}

/* CARDABEL LIST BOX */

.cardabel-listbox-checkbox {
  height: 29px;
  display: inline-flex;
  width: auto;
}

.cardabel-listbox-checkbox span {
  vertical-align: middle;
  padding-left: 15px;
  padding-top: 1px;
  display: inline-flex;
  min-width: 100%;
  contain: content;
  white-space: nowrap;
}

.cardabel-listbox-checkbox .mat-checkbox-frame {
  height: 20px!important;
  width: 20px!important;
}

.cardabel-listbox-checkbox mat-checkbox {
  padding-left: 10px;
  margin-top: 2px;
 /* position: absolute; */
}

.cardabel-listbox-checkbox .mat-checkbox-inner-container {
  height: 20px;
  width: 20px;
}

.cardabel-filter-menu-mat-checkbox .mat-checkbox-ripple {
  left: calc(50% - 12px)!important;
  top: calc(50% - 12px)!important;
  height: 24px!important;
  width: 24px!important;
  border-radius: 50%;
}

#currencyViewport .cdk-virtual-scroll-content-wrapper {
  width: 100%;
  contain: none;
}


#checkboxAll mat-checkbox  .mat-checkbox-layout {
  padding-top: 17px;
  padding-left: 2px;

}
#checkboxAll .mat-checkbox-checked .mat-checkbox-frame {
  height: 20px!important;
  width: 20px!important;
  border-color: white!important;
}

.reconciled .mat-checkbox-background, .reconciled.mat-checkbox-checked .mat-checkbox-background {
  background-color: green!important;
}

.reconciled.virtual-table-column {
  color: green!important;
  font-weight: bold;
}

.notreconciled .mat-checkbox-background, .notreconciled.mat-checkbox-checked .mat-checkbox-background {
  background-color: black!important;
}

.notreconciled.virtual-table-column {
  font-weight: bold;
}

.ongoingModification .mat-checkbox-background, .ongoingModification.mat-checkbox-checked .mat-checkbox-background {
  background-color: red!important;
}

.ongoingModification.virtual-table-column {
  color: red!important;
  font-weight: bold;
}




.mat-pseudo-checkbox {
  height: 20px!important;
  width: 20px!important;
  border: 1px solid rgb(166, 166, 166)!important;
}

.mat-pseudo-checkbox-checked::after {
  top: 5.4px!important;
  left:3px!important;
  width: 10px!important;
}
.mat-pseudo-checkbox-checked {
  height: 20px!important;
  width: 20px!important;
  border-color: white!important;
}

.dropup, .dropdown {
  z-index: 20000!important;
}

/*nav css*/
.navigationSettings, .moveColumns, .scrollColumns, .toggleColumns, .saveBookmark {
  z-index: 20000!important;
}
.navigationSettings .ui-dropdown {
  width: 177px;
}
.navigationSettings .trash {
  color: #e91224;
}
.navigationSettings .plus {
  color: #ddd;
}
.navigationSettings .ui-dialog-content,.moveColumns .ui-dialog-content, .scrollColumns .ui-dialog-content, .toggleColumns .ui-dialog-content {
 /* min-width: 700px!important; */
  height: 452px;
  overflow-x: hidden!important;
}

.filterSignedColumns .ui-dialog-content {
  min-width: 400px!important;
  height: 50px;
  overflow-x: hidden!important;
}

.saveBookmark .ui-dialog-content {
  height: 350px;
  min-width: 350px;
  overflow-x: hidden!important;
}

.btn-cancel, .btn-confirm {
  margin-top: -3px!important;
}

#theme-dark .btn-cancel {
  color: #ddd;
  border-color: #ddd;
  background: #222;

}


#navigationSettings .cardabelSelectionList {height: 150px;}

.cardabelSelectionList cdk-virtual-scroll-viewport {
  height: 300px;

}
  
.dropdown-sm .cardabelSelectionList {
   height: 150px;
 }

 .dropdown-sm form, .dropdown-md form, .dropdown-lg form, .dropdown-sm .cardabelSelectionList, .dropdown-md .cardabelSelectionList, .dropdown-lg .cardabelSelectionList {
   max-width: 300px;
 } 

 .dropdown-sm cdk-virtual-scroll-viewport {
   height: 140px;
 }
 .dropdown-md cdk-virtual-scroll-viewport {
   height: 240px;
 }

 .dropdown-lg cdk-virtual-scroll-viewport {
   height: 340px;
 }
.dropdown-md .cardabelSelectionList{
  height: 250px;
}

.dropdown-lg .cardabelSelectionList  {
  height: 350px;
}

.dropdown-width-170 {
  width: 170%!important;
}

button {
  overflow-x: hidden;
  text-overflow: ellipsis;
}

.mat-paginator-container {
  min-height: 25px!important;
  height: 25px!important;
  padding: 0!important;
}

.mat-paginator .mat-form-field-infix {
  padding:0;
}
.mat-paginator, .mat-paginator-page-size .mat-select-trigger {
  font-size: 10px!important;
}

.mat-paginator-range-actions button {
  height: 35px;
  width: 35px;
}

.ui-confirmdialog.ui-dialog .ui-dialog-content {
  height: auto;
}
.ui-confirmdialog .errorConfirm {
  color: red;
  opacity: 0.8;
}

.ui-picklist .ui-button {
  margin-bottom: 0!important;
}

body .ui-button.ui-button-text-only .ui-button-text {
  padding: 0!important;
  padding-top: 5px!important;
  padding-bottom: 5px!important;
  text-align: center;
}



.btn-basic {

      color: #6c757d;  
      background-color: #eaeaea;  
  
}
.btn-basic:hover, .btn-basic:focus {
  
      color: #fff;  
      background-color: #097682;  
  
}

.ui-picklist-buttons .ui-button {
  margin-bottom: 0.25em!important;
}

.ui-sidebar {
  height: 100vh;
}

p-confirmdialog .ui-button.ui-button-text-only .ui-button-text {
  padding:0.429em 1em!important;
}

.ui-dialog .pi-window-maximize {
  display: none!important;
}

.ui-toast-detail {
  overflow-wrap: break-word;
}



.cardabelSelectionList cdk-virtual-scroll-viewport::ng-deep {
  height: 100%;
  width: 100%;
  overflow-x:auto;
  overflow-y: hidden;
}
.cardabelSelectionList .cdk-virtual-scroll-content-wrapper {
  contain: none;
}
/* .cardabelSelectionList .cdk-virtual-scroll-content-wrapper {
  overflow-x:auto;
  overflow-y: hidden;
} */


.menu-filters-item {
min-height: 48px!important;
white-space: unset!important;
overflow: unset!important;
white-space: nowrap!important;
max-width: unset!important;
width: fit-content!important;
}


.grid-column-menu {
  max-width: fit-content!important;
}

.dropdownAbove.dropdown, .dropdownAbove.dropup, .dropdownAbove.dropup mat-header {
  z-index: 30000!important;
}

.dropdownAbove1.dropdown, .dropdownAbove1.dropup, .dropdownAbove1.dropup mat-header {
  z-index: 30001!important;
}

.suspens {
  /*border:3px solid #007ad9!important; */
 
  border:3px solid #0daaba!important;
}
.reco_manual {
  border:3px solid chocolate!important;
}
.manual_only {
  border:3px solid red!important;
}
.card_one_manual {
  background-color: orange!important;
}
.one_manual {
  border:3px solid orange!important;
}
.many_manual {
  border:3px solid black!important;
}
.card_many_manual {
  background-color:black!important;
  color: white!important;
}
.card_in_manual{
  background-color:chocolate!important;
}
.suspens_only {
 /* background-color: #007ad9!important; */
 background-color: #0daaba!important;
 
}
.matchable {
  border: 3px solid mediumseagreen!important;
}
.card_matchable{
  background-color: mediumseagreen!important;
}

.cardabel-selection-filters-menu-scroller {
  height: 150px;
}



.cdk-drag-dragging, .cdk-drag-preview {
  z-index:30000!important;
}

body .ui-selectbutton .ui-button.ui-state-active {
  color: #ffffff!important;
  background-color: #0daaba!important;
  border-color: #0daaba!important;
}

.kernel {
  background-color:#fff;
  border:3px solid;    
  height:15px;
  border-radius:50%;
  -moz-border-radius:50%;
  -webkit-border-radius:50%;
  width:15px;
}
